html,
body {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

body {
  min-height: 100dvh;
}

#__next {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100dvh;
}
a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* Fixes creating new lines with single enter key press */
.tiptap {
  display: flex;
  flex-direction: column;
  outline: none;
}

/* For Placeholder extension */
/* https://tiptap.dev/docs/editor/api/extensions/placeholder#additional-setup */
.tiptap p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
